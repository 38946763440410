import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-146c4049"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id", "onClick"]
const _hoisted_2 = ["src", "id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    id: "slider",
    class: _normalizeClass({ 'slider_center': _ctx.styleType==2 })
  }, [
    _createElementVNode("div", {
      id: "menus",
      class: _normalizeClass({ 'menus_row': _ctx.styleType==2 })
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.$props.items, (item, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: index,
          id: this.$props.fullImg ? 'menuItem_fillImg' : (_ctx.selectedIndex === index
            ? 'menuItem_selected'
            : 'menuItem'),
          class: _normalizeClass({ 'row_active': ( _ctx.selectedIndex === index && _ctx.styleType==2 ) }),
          onClick: ($event: any) => (_ctx.onClick(index))
        }, [
          _createElementVNode("img", {
            src: item.icon,
            id: this.$props.fullImg ? 'img_full' : 'img_padding'
          }, null, 8, _hoisted_2)
        ], 10, _hoisted_1))
      }), 128))
    ], 2)
  ], 2))
}